<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw !important;
}

/* width */
::-webkit-scrollbar {
  width:15px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(151, 156, 151); 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000; 
}

.container {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
<script>

export default {
    name: 'App',
          created() {




  },
              computed:{
// ...mapState({mostrarFootButtons: state => state.classes.mostrarFootButtons}),

    },
        methods: {
 
        }
}

</script>