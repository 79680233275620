<template>
  <component :is="layout" class="classlayout">
    <slot />
  </component>
</template>
<style>
.classlayout{
  width: 100vw !important;
}
</style>
<script>
const defaultLayout = 'AppLayoutDefault'
export default {
  name: "AppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    }
  }
}
</script>