import Vue from 'vue'
import VueRouter from 'vue-router'
//import goTo from 'vuetify/lib/services/goto'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/MainView.vue'),
    meta: {
     //layout: 'AppLayoutHome'
    }
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('@/views/ServiciosView.vue'),
    meta: {
      //layout: 'AppLayoutAbout'
    }
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: () => import('@/views/ProyectosView.vue'),
    meta: {
      //layout: 'AppLayoutAbout'
    }
  },
//   {
//     path: '/contacts',
//     name: 'Contacts',
//     component: () => import('@/views/Contacts.vue'),
//     meta: {
//       layout: 'AppLayoutContacts'
//     }
//   },
//   {
//     path: '/test',
//     name: 'Test',
//     component: () => import('@/views/Home.vue')
//   }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  // scrollBehavior: (to, from, savedPosition) => {
  //   let scrollTo = 0

  //   if (to.hash) {
  //     scrollTo = to.hash
  //   } else if (savedPosition) {
  //     scrollTo = savedPosition.y
  //   }
  //   scrollTo = savedPosition.y
  //   return goTo(scrollTo)
  // },


  routes
})

export default router